<template>
    <div class="MenuCommon">
        <div class="MenuCommon_head">
            <headassembly></headassembly>
        </div>
        <div class="MenuCommon_body">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import headassembly from "../components/headassembly.vue";
import api from "../api/Grantsandmilestones"
import { mapState } from "vuex";
export default {
    data(){
       return{

       }
    },
    computed: mapState(["projectId",]),
    components: {
    headassembly,
    },
    created(){
        this.fn()
    },
    methods: {
        fn(){
            console.log(this.projectId,"@@@@@@");
            if(this.projectId!=null){
                let parame={
                 topicId:this.projectId,
                 menu:"project"
               }
               api.judgeData(parame).then(res=>{
                 console.log(res,"成功没2222222222");
                  if(res.data.Status==1){
                      this.$store.commit("midshow",false)
                  }else{
                      this.$store.commit("midshow",true)
                  }
               })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.MenuCommon{
  width: 1920px;
  height: 100%;
  .MenuCommon_head{
      width: 100%;
      height: 80px;
      //background: fuchsia;
  }
  .MenuCommon_body{
      width: 100%;
      height: 91%;
      background: #F0F7FD;
  }
}
</style>
